import Browser, { MainGrid } from "layouts/web/Browser";
import Home from "views/session/Home";
import { APP } from "consts";

export const metadata = {
  title: `Iniciar sesión | ${APP.alias}`,
};

export default function SignInPage() {
  return (
    <Browser metadata={metadata}>
      <MainGrid
        title="<b>[BUP]</b> te ayuda a conocer gente con intereses en común, encontrar amigos y hacer panoramas"
        src="assets/signin.webp"
      >
        <Home />
      </MainGrid>
    </Browser>
  );
}
