import { Button, Field, Form, Link, Typography } from "components";
import PropTypes from "prop-types";

function Restore({ form, disabled }) {
  return (
    <Form
      form={form}
      hidden={form.values.view !== "restore"}
      title="Restaurar contraseña"
    >
      <Field
        form={form}
        disabled={disabled}
        label="Correo electrónico"
        message="La dirección de correo electrónico no esta registrada"
        name="email"
        type="email"
      />
      <Typography variant="p">
        {!disabled
          ? "Ingresa tu dirección de correo electrónico para restablecer tu contraseña. Enviaremos un enlace de restauración."
          : "¡Listo! Hemos enviado un enlace de restauración a tu correo electrónico. Elige una nueva contraseña segura."}
      </Typography>
      <Button disabled={disabled} size="large" type="submit" fullWidth>
        {!disabled ? "Enviar" : "¡Enviado!"}
      </Button>
      <Typography className="BupForm-link" variant="span">
        ¿Recuperaste tu contraseña?{" "}
        <Link onClick={() => form.setValues({ view: "login" })}>
          Inicia sesión
        </Link>
      </Typography>
    </Form>
  );
}

Restore.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Restore;
